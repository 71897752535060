import Vue from 'vue'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInterfaces(ctx, payload) { // fetchTasks
      return new Promise((resolve, reject) => {
        axiosIns
          .get('/api/v2/interfaces', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInterfaceIdentification(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/api/v2/interfaces/update/${payload.id}`, payload.interfaceProperties)
          .then(response => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Interface Identification',
                text: `Updated Identification for Interface: ${payload.display_id}`,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            resolve(response)
          })
          .catch(error => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to update Interface',
                text: `An error occurred when attempting to update Interface Identification.
              Server returned Status ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    },
    updateInterfaceCharacteristics(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/api/v2/interfaces/update/${payload.id}`, payload.interfaceProperties)
          .then(response => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Updated Interface Characteristics',
                text: `Updated Characteristics for Interface: ${payload.display_id}`,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            resolve(response)
          })
          .catch(error => {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failed to update Interface',
                text: `An error occurred when attempting to update Interface Characteristics.
              Server returned Status ${error}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            reject(error)
          })
      })
    },
    updateFunctionAllocations(ctx, payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/api/v2/interfaces/update_interface_fns/${payload.id}`, payload.associatorData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateStandardsLinking(ctx, payload) {
      console.log('Store Standards Data: ', payload)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/api/v2/interfaces/update_interface_standards/${payload.id}`, payload.associatorData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateRequirementsAllocations(ctx, payload) {
      console.log('Store Requirement Data: ', payload)
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`/api/v2/interfaces/update_interface_reqs/${payload.id}`, payload.associatorData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllocatedReleases(ctx, payload) {
      const config = {
        params: { model: payload.model },
      }
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`/api/v2/interfaces/get_interface_releases/${payload.id}`, config)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateReleasesAllocations(ctx, payload) {
      console.log('Store Release Data: ', payload)
      return new Promise((resolve, reject) => {
        axiosIns
          .post('/api/v2/interfaces/disassociate_releases_from_interface', payload.toRemove)
          .then(() => {
            axiosIns
              .post('/api/v2/interfaces/associate_releases_with_interface', payload.toAdd)
              .then(response => resolve(response))
          })
          .catch(error => reject(error))
      })
    },
    // addTask(ctx, taskData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/todo/tasks', { task: taskData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // updateTask(ctx, { task }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`/apps/todo/tasks/${task.id}`, { task })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    // removeTask(ctx, { id }) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .delete(`/apps/todo/tasks/${id}`)
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
