<template>
  <b-modal
    id="export-interfaces-modal"
    title="Export Interfaces to XLSX"
    size="xl"
    no-close-on-backdrop
    no-close-on-esc
    @ok="generateTable"
    @shown="onShown"
  >
    <h5 class="mt-50 text-primary">
      Select the interfaces to export
    </h5>
    <b-row v-if="loaded">
      <b-col>
        <b-table
          :items="allInterfaces"
          :fields="interfaceFields"
          class="colPadding"
          small
          hover
          responsive
          no-border-collapse
          sticky-header="80vh"
          @row-clicked="toggleInterface"
        >
          <template v-slot:head(selected)>
            <b-form-checkbox v-model="global" @input="selectAll" />
          </template>
          <template v-slot:cell(selected)="{ item }">
            <b-form-checkbox v-model="item.selected" style="pointer-events: none" />
          </template>
          <template v-slot:cell(display_id)="{ item }">
            {{ item.interface_properties.display_id || '-' }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-overlay v-else show opacity="0">
      <template v-slot:overlay>
        <h1 style="display: inline-block">
          Loading Interfaces
        </h1> <b-spinner />
      </template>
      <div style="height: 50vh;" />
    </b-overlay>

    <template v-slot:modal-footer="{ok, cancel}">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="success" :disabled="loading_status" @click="ok()">
        <span v-if="loading_status">
          <b-spinner small type="grow" />
          Exporting...
        </span>
        <span v-else>
          Export Interfaces
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

// eslint-disable-next-line import/no-extraneous-dependencies
const FileSaver = require('file-saver')

export default {
  name: 'ExportInterfacesReport',
  data() {
    return {
      parentSpec: null,
      childSpec: null,
      loading_status: false,
      loaded: false,
      direction: 'after',
      allInterfaces: [],
      interfaceFields: [
        {
          key: 'selected', thClass: 'position-sticky', thStyle: { width: '5%' },
        },
        {
          key: 'display_id', label: 'Identification', sortable: true, thClass: 'position-sticky text-left', tdClass: 'text-left', thStyle: { width: '22.5%' },
        },
        {
          key: 'source', sortable: true, thClass: 'position-sticky', thStyle: { width: '22.5%' },
        },
        {
          key: 'target', label: 'Destination', sortable: true, thClass: 'position-sticky', thStyle: { width: '22.5%' },
        },
        {
          key: 'resource', label: 'Resource Exchanged', sortable: true, thClass: 'position-sticky', thStyle: { width: '27.5%' },
        },
      ],
      selected: [],
      global: false,
    }
  },
  methods: {
    onShown() {
      this.loaded = false
      const params = {
        model: this.$store.state.model.id,
      }
      this.$http
        .get('/api/v2/interfaces/get_interfaces_simple', { params })
        .then(({ data }) => {
          this.allInterfaces = data
          this.allInterfaces = this.allInterfaces.map(i => ({ selected: false, ...i }))
          this.global = false
          this.loading_status = false
          this.loaded = true
        })
    },
    selectAll() {
      this.allInterfaces.forEach(i => {
        i.selected = this.global
      })
    },
    toggleInterface(iface) {
      iface.selected = !iface.selected
    },
    generateTable(evt) {
      evt.preventDefault()
      this.loading_status = true
      if (!this.allInterfaces.some(iface => iface.selected)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'No Interfaces selected',
            text: 'Select the interfaces you would like to export.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        this.loading_status = false
        return
      }

      let now = new Date()
      now = moment(now).format('MMM_DD_HH_MM_SS')
      // Create and append data to the json file
      const filename = `interfaces_${now}.xlsx`

      const params = {
        model: this.$store.state.model.id,
        interfaces: this.allInterfaces.filter(iface => iface.selected).map(c => (c.interface_properties.id)),
      }
      this.$http
        .post('/api/v2/interfaces/report', params, { responseType: 'blob' })
        .then(({ data }) => {
          FileSaver.saveAs(data, filename)
          this.loading_status = false
          this.$bvModal.hide('export-interfaces-modal')
        })
    },
    cancel() {
      this.loading_status = false
    },
  },
}
</script>

<style lang="scss">
@import 'src/assets/scss/variables/_variables.scss';

.v-select-style-overrides {
  .vs__dropdown-toggle, .vs__dropdown-menu {
    text-transform: capitalize;
  }
}

.dark-layout {
  .vs__dropdown-menu {
    background: yellow;
    li {
      color: red;
    }
  }

  .v-select-style-overrides {
    .vs__dropdown-option--selected {
      background: lighten($info, 0.5%);
    }
  }

  .colPadding td {
    padding: .5em .5em;
  }
}
</style>
