<template>
  <b-modal
    id="add-interface-modal"
    title="Add Interface"
    size="xl"
    no-close-on-esc
    no-close-on-backdrop
    @show="onShow"
    @ok="submitFunction"
  >
    <validation-observer ref="simpleRules">
      <form ref="form">
        <div v-if="isIdentificationTabActive" id="identificationTab">
          <h5>Identification</h5>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Identifier: " label-for="interface-dispid">
                <validation-provider
                  #default="{ errors }"
                  name="Identifier"
                  rules="required"
                >
                  <b-form-input
                    id="interface-dispid"
                    v-model="display_id"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Interface Display ID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Name: " label-for="interface-name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    id="interface-name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Interface Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description: " label-for="interface-desc">
                <b-form-textarea
                  id="interface-desc"
                  v-model="description"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <b-form-group label="Source:" label-for="interface-source">
                <validation-provider
                  #default="{ errors }"
                  name="Source"
                  rules="required"
                >
                  <v-select
                    id="interface-source"
                    v-model="source"
                    :options="performer_opts"
                    :reduce="optObject => optObject.value"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Target:" label-for="interface-target">
                <validation-provider
                  #default="{ errors }"
                  name="Target"
                  rules="required"
                >
                  <v-select
                    id="interface-target"
                    v-model="destination"
                    :options="performer_opts"
                    :reduce="optObject => optObject.value"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Resource/Data Element:" label-for="interface-resource">
                <validation-provider
                  #default="{ errors }"
                  name="Resource"
                  rules="required"
                >
                  <v-select
                    id="interface-resource"
                    v-model="resource"
                    :options="resource_opts"
                    :reduce="optObject => optObject.value"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div v-else id="characteristicsTab">
          <h5>Characteristics</h5>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Interface Type: " label-for="interface-type">
                <b-form-input
                  id="interface-type"
                  v-model="type"
                  list="type-list"
                />
                <datalist id="type-list">
                  <option
                    v-for="t in interfaceTypes"
                    :key="t.name"
                  >
                    {{ t.name }}
                  </option>
                </datalist>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <label for="entitySelect2">Classification</label>
              <b-form-select
                id="entitySelect2"
                v-model="classification"
                :options="securityClassifications"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group v-slot="{ ariaDescribedby }" label="Continuous: " label-for="continuous">
                <b-form-radio v-model="continuous" :aria-describedby="ariaDescribedby" name="cont-radios" value="continuous">
                  Continuous
                </b-form-radio>
                <b-form-radio v-model="continuous" :aria-describedby="ariaDescribedby" name="cont-radios" value="discrete">
                  Discrete
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group v-slot="{ ariaDescribedby }" label="Synchronous: " label-for="synchronous">
                <b-form-radio v-model="synchronous" :aria-describedby="ariaDescribedby" name="sync-radios" value="synchronous">
                  Synchronous
                </b-form-radio>
                <b-form-radio v-model="synchronous" :aria-describedby="ariaDescribedby" name="sync-radios" value="asynchronous">
                  Asynchronous
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Frequency: " label-for="interface-freq">
                <b-form-input
                  id="interface-freq"
                  v-model="frequency"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Connection Method: " label-for="interface-connection">
                <b-form-input
                  id="interface-connection"
                  v-model="connection"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Integration Readiness Level:" label-for="interface-irl">
                <b-form-select
                  id="interface-irl"
                  v-model="irl"
                  :options="irls"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Owner: " label-for="interface-owner">
                <b-form-input
                  id="interface-owner"
                  v-model="owner"
                  list="stakeholder-list"
                />
                <datalist id="stakeholder-list">
                  <option
                    v-for="t in stakeholders"
                    :key="t.name"
                  >
                    {{ t.name }}
                  </option>
                </datalist>
              </b-form-group>
            </b-col>
          </b-row>

          <hr>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Notes: " label-for="interface-note">
                <b-form-textarea
                  id="interface-note"
                  v-model="notes"
                  rows="3"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </form>
    </validation-observer>
    <template v-slot:modal-footer="{ok, cancel}">
      <div class="w-100 d-flex flex-row justify-content-between">
        <!-- Next and Previous buttons -->
        <div>
          <b-button
            v-if="isIdentificationTabActive"
            variant="outline-primary"
            @click.prevent="validationFormNext"
          >
            Next
            <font-awesome-icon
              :icon="['fas', 'angle-right']"
              class=""
            />
          </b-button>
          <b-button
            v-else
            variant="outline-primary"
            @click="isIdentificationTabActive = true"
          >
            <font-awesome-icon
              :icon="['fas', 'angle-left']"
            />
            Previous
          </b-button>
        </div>

        <!-- Add and Cancel buttons-->
        <div>
          <b-button
            class="mr-1"
            variant="success"
            @click.prevent="validationForm"
          >
            <span v-if="loading_status">
              <b-spinner small type="grow" />
              Adding Interface...
            </span>
            <span v-else>
              Add Interface
            </span>
          </b-button>
          <b-button variant="outline-secondary" @click="cancel()">
            Dismiss
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BModal, BRow, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,

    BRow,
    BCol,
    BFormGroup,
    BSpinner,
    BModal,
    BFormTextarea,
    BFormInput,
    BButton,
  },
  props: {
    direction: {
      type: String,
      default: null,
    },
    cpt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      model: this.$store.state.model.id,
      display_id: '',
      name: '',
      description: '',
      source: '',
      destination: '',
      resource: '',
      type: '',
      continuous: 'continuous',
      synchronous: 'asynchronous',
      irl: '',
      frequency: '',
      connection: '',
      owner: '',
      notes: '',
      reqs: [],
      loading_status: false,
      classification: '',
      required,
      // Tab stuff
      isIdentificationTabActive: true,
    }
  },
  computed: {
    ...mapState({
      performers: state => state.domainModel.performers,
      resources: state => state.domainModel.entities,
      stakeholders: state => state.canvas.stakeholders,
    }),
    ...mapGetters({
      interfaceTypes: 'constants/interfaceTypes',
      irls: 'constants/irls',
      securityClassifications: 'constants/securityClassifications',
    }),
    performer_opts() {
      return this.performers.map(x => ({ value: x.id, text: x.name }))
    },
    resource_opts() {
      return this.resources.map(x => ({ value: x.id, text: x.name }))
    },
  },
  methods: {
    onShow() {
      this.$store.dispatch('domainModel/getComponents', { forced: true })
      this.$store.dispatch('canvas/fetchStakeholders')
      this.populate()
      this.clearAllFields()
    },
    populate() {
      // console.log('Preselect: ', this.cpt, this.direction)
      if (this.cpt && this.cpt !== '') {
        if (this.direction === 'input') {
          this.destination = this.cpt
        } else {
          this.source = this.cpt
        }
      }
      this.classification = this.$store.state.model.defaultSecurityClassification
    },
    clearAllFields() {
      this.display_id = ''
      this.name = ''
      this.description = ''
      this.source = ''
      this.destination = ''
      this.resource = ''
      this.type = ''
      this.continuous = 'continuous'
      this.synchronous = 'asynchronous'
      this.irl = ''
      this.frequency = ''
      this.connection = ''
      this.owner = ''
      this.notes = ''
      this.reqs = []
      this.loading_status = false
      this.isIdentificationTabActive = true
      this.classification = this.$store.state.model.defaultSecurityClassification
    },
    validationForm() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.submitFunction()
          }
        })
    },
    validationFormNext() {
      this.$refs.simpleRules.validate()
        .then(success => {
          if (success) {
            this.isIdentificationTabActive = false
          }
        })
    },
    submitFunction() {
      this.loading_status = true
      const payload = {
        model: this.$store.state.model.id,
        display_id: this.display_id,
        name: this.name,
        description: this.description,
        source: this.source,
        destination: this.destination,
        resource: this.resource,
        type: this.type,
        continuous: this.continuous,
        synchronous: this.synchronous,
        frequency: this.frequency,
        connection: this.connection,
        irl: this.irl,
        owner: this.owner,
        notes: this.notes,
        reqs: this.reqs,
        classification: this.classification,
      }
      this.$http.post('/api/v2/interfaces', payload).then(({ data }) => {
        this.loading_status = false
        this.$bvModal.hide('add-interface-modal')
        this.$router.replace({
          name: this.$route.name,
          query: { focus: data.id },
        })
        this.$emit('added', data)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-autosuggest.scss';
</style>
