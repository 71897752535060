<template>
  <div>
    <b-button
      v-b-tooltip.hover.left="`Add Resource Properties/Attributes`"
      variant="flat-success"
      size="sm"
      class="hidden mb-25"
      @click="$bvModal.show('add-property-modal')"
    >
      <feather-icon icon="PlusCircleIcon" />
    </b-button>

    <span class="font-medium-2">Resource Properties/Attributes for {{ entity.name }}</span>

    <b-list-group
      v-if="entityArray.length > 0"
    >
      <list-group-item-property
        v-for="(item,index) in entityArray"
        :key="index"
        :property="item"
        :entity="entity.id"
        @propertyChanged="$emit('propertyChanged')"
      />
    </b-list-group>

    <div v-else>
      <span class="ml-3 font-small-3 text-muted">No resource definition</span>
    </div>
    <AddProperty />
  </div>
</template>

<script>
import { BButton, BListGroup, VBTooltip } from 'bootstrap-vue'
import ListGroupItemProperty from '@/components/Forms/ListGroupItems/ListGroupItemProperty.vue'
import AddProperty from '@/components/Domain/Modals/Properties/AttributeAdd.vue'

export default {
  name: 'ListGroupProperty',
  components: {
    BButton,
    BListGroup,
    ListGroupItemProperty,
    AddProperty,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    entityArray: {
      type: Array,
      required: true,
    },
    entity: {
      type: Object,
      required: true,
    },
  },
}
</script>
