<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isInterfaceHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="updateLocalProp"
    >
      <!-- @hidden="clearForm" -->
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Interface <span class="text-primary">{{ localInterfaceProp.display_id }}</span>
          </h5>
          <div class="d-inline-flex">
            <div
              v-b-modal.delete-interface-modal
              title="Delete Interface"
              class="mr-1"
            >
              <feather-icon
                class="cursor-pointer text-danger"
                icon="TrashIcon"
                size="16"
              />
            </div>
            <div
              title="Close"
              @click="hide"
            >
              <feather-icon
                class="cursor-pointer"
                icon="XIcon"
                size="16"
              />
            </div>
          </div>

          <DeleteInterfaceModal @deleted="$emit('deleted'); hide()" />
        </div>

        <!-- Body -->
        <b-tabs justified class="px-2">
          <!-- Identification -->
          <b-tab title="Identification">
            <b-row>
              <b-col cols="6">
                <b-form-group label="Identifier" label-for="interface-dispid">
                  <b-form-input
                    id="interface-dispid"
                    v-model="localInterfaceProp.display_id"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Version" label-for="interface-version">
                  <b-form-input
                    id="interface-version"
                    v-model="localInterfaceProp.version"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Name" label-for="interface-name">
                  <b-form-input
                    id="interface-name"
                    v-model="localInterfaceProp.name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Description" label-for="interface-desc">
                  <b-form-textarea
                    id="interface-desc"
                    v-model="localInterfaceProp.description"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group label="Source" label-for="interface-source">
                  <v-select
                    v-model="localInterfaceProp.source"
                    :options="performerOpts"
                    :reduce="optObject => optObject.value"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Target" label-for="interface-target">
                  <v-select
                    v-model="localInterfaceProp.destination"
                    :options="performerOpts"
                    :reduce="optObject => optObject.value"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Resource/Data Element" label-for="interface-resource">
                  <v-select
                    v-model="localInterfaceProp.resource"
                    :options="resourceOpts"
                    :reduce="optObject => optObject.value"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Status" label-for="interface-status">
                  <v-select
                    v-model="localInterfaceProp.status"
                    :options="interfaceStatus"
                    :reduce="optObject => optObject.text"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Classification" label-for="security-classification">
                  <v-select
                    v-model="localInterfaceProp.classification"
                    :options="securityClassifications"
                    :reduce="optObject => optObject.text"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              variant="success"
              @click="updateIdentification(); hide()"
            >
              Update Identification
            </b-button>
          </b-tab>

          <!-- Characteristics -->
          <b-tab title="Characteristics">
            <!-- Interface Type-->
            <b-row>
              <b-col cols="12">
                <b-form-group label="Interface Type" label-for="interface-type">
                  <v-select
                    v-model="localInterfaceProp.type"
                    :options="interfaceTypes"
                    :reduce="optObject => optObject.text"
                    label="text"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Continuous, Discrete, Synchronous, Asynchronous -->
            <b-row>
              <b-col cols="6" class="border-right border-2">
                <b-form-group v-slot="{ ariaDescribedby }" label="Continuous" label-for="continuous">
                  <b-form-radio
                    v-model="localInterfaceProp.continuous"
                    :aria-describedby="ariaDescribedby"
                    name="cont-radios"
                    value="continuous"
                  >
                    Continuous
                  </b-form-radio>
                  <b-form-radio
                    v-model="localInterfaceProp.continuous"
                    :aria-describedby="ariaDescribedby"
                    name="cont-radios"
                    value="discrete"
                  >
                    Discrete
                  </b-form-radio>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group v-slot="{ ariaDescribedby }" label="Synchronous" label-for="synchronous">
                  <b-form-radio
                    v-model="localInterfaceProp.synchronous"
                    :aria-describedby="ariaDescribedby"
                    name="sync-radios"
                    value="synchronous"
                  >
                    Synchronous
                  </b-form-radio>
                  <b-form-radio
                    v-model="localInterfaceProp.synchronous"
                    :aria-describedby="ariaDescribedby"
                    name="sync-radios"
                    value="asynchronous"
                  >
                    Asynchronous
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Frequency, Connection Method -->
            <b-row>
              <b-col cols="6">
                <b-form-group label="Frequency" label-for="interface-freq">
                  <b-form-input
                    id="interface-freq"
                    v-model="localInterfaceProp.frequency"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Connection Method" label-for="interface-connection">
                  <b-form-input
                    id="interface-connection"
                    v-model="localInterfaceProp.connection"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Volumes -->
            <b-row>
              <b-col>
                <b-form-group label="Average Volume/Load" label-for="volume_avg">
                  <b-form-input
                    id="volumne-avg"
                    v-model="localInterfaceProp.volume_avg"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Peak Volume/Load" label-for="volume_peak">
                  <b-form-input
                    id="volumne-peak"
                    v-model="localInterfaceProp.volume_peak"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Latency -->
            <b-row>
              <b-col>
                <b-form-group label="Average Latency" label-for="latency_avg">
                  <b-form-input
                    id="latency_avg"
                    v-model="localInterfaceProp.latency_avg"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Peak Latency" label-for="latency_peak">
                  <b-form-input
                    id="latency_peak"
                    v-model="localInterfaceProp.latency_peak"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Errors -->
            <b-row>
              <b-col>
                <b-form-group label="Errors per/Minute Tolerance" label-for="epm_max">
                  <b-form-input
                    id="epm_max"
                    v-model="localInterfaceProp.emp_max"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Timeout" label-for="timeout">
                  <b-form-input
                    id="timeout"
                    v-model="localInterfaceProp.timeout"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Retries -->
            <b-row>
              <b-col>
                <b-form-group label="Retry Count" label-for="retry_count">
                  <b-form-input
                    id="retry_count"
                    v-model="localInterfaceProp.retry_count"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Retry Interval" label-for="retry_interval">
                  <b-form-input
                    id="retry_interval"
                    v-model="localInterfaceProp.retry_interval"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Exception Handling -->
            <b-row>
              <b-col>
                <b-form-group label="Exception Handling" label-for="exception_handling">
                  <b-form-textarea
                    id="exception_handling"
                    v-model="localInterfaceProp.exception_handling"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Owner, Integration Readiness Level (IRL)-->
            <b-row>
              <b-col cols="6">
                <b-form-group label="Owner" label-for="interface-owner">
                  <v-select
                    v-model="localInterfaceProp.owner"
                    :options="stakeholders"
                    :reduce="optObject => optObject.name"
                    label="name"
                    class="assignee-selector"
                    input-id="assignee"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Integration Readiness Level" label-for="interface-irl">
                  <b-form-select
                    id="interface-irl"
                    v-model="localInterfaceProp.irl"
                    :options="irls"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Notes -->
            <b-row>
              <b-col cols="12">
                <b-form-group label="Notes" label-for="interface-note">
                  <b-form-textarea
                    id="interface-note"
                    v-model="localInterfaceProp.notes"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              variant="success"
              @click="updateCharacteristics(); hide()"
            >
              Update Characteristics
            </b-button>
          </b-tab>

          <!-- Allocations -->
          <b-tab title="Allocations">
            <!-- Requirements -->
            <b-row class="mb-2">
              <b-col v-if="areRequirementsLoading" class="d-flex justify-content-start ml-1">
                <b-spinner
                  small
                  class="align-self-center mr-1"
                />
                Linked Requirements
              </b-col>
              <b-col v-else>
                <list-group-requirement
                  v-if="selectedInterface"
                  label="Requirements"
                  :entity-array="interfaceRequirements"
                  modal="associate-interface-req-modal"
                />
              </b-col>
            </b-row>

            <!-- Issues -->
            <b-row class="mb-2">
              <b-col>
                <b-button
                  v-b-tooltip.hover.top="'Associate Issues'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  class="btn-icon ml-25 mr-25"
                  variant="flat-success"
                  @click="$bvModal.show('associate-interface-issue-modal')"
                >
                  <feather-icon
                    icon="LinkIcon"
                  />
                </b-button>
                <span class="font-medium-2">Linked Issues</span>
                <b-button
                  v-if="selectedInterface.id"
                  v-b-tooltip.hover.top="'Create Issue'"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  class="btn-icon"
                  variant="flat-success"
                  @click="issueModalRaise(selectedInterface.id, 'Interface', interfaceStorePull)"
                >
                  <feather-icon
                    icon="PlusIcon"
                  />
                </b-button>
                <b-list-group
                  v-if="interfaceIssues.length > 0"
                  style="max-height: 30vh; overflow-y: auto"
                >
                  <ListGroupItemIssue
                    v-for="(item,index) in interfaceIssues"
                    :key="index"
                    :issue="item"
                  />
                </b-list-group>
                <div v-else>
                  <span class="ml-3 font-small-3 text-muted">No linked Issues</span>
                </div>
              </b-col>
            </b-row>

            <!-- Releases -->
            <b-row class="mb-2">
              <b-col>
                <list-group-release
                  v-if="selectedInterface"
                  label="Releases"
                  :entity-array="interfaceReleases"
                  modal="associate-interface-release-modal"
                />
              </b-col>
            </b-row>

            <!-- Functions -->
            <b-row class="mb-2">
              <b-col v-if="areFunctionsLoading" class="d-flex justify-content-start ml-1">
                <b-spinner
                  small
                  class="align-self-center mr-1"
                />
                Linked Functions
              </b-col>
              <b-col v-else>
                <list-group-entity
                  v-if="selectedInterface"
                  label="Functions"
                  :entity-array="interfaceFunctions"
                  modal="associate-interface-fn-modal"
                />
              </b-col>
            </b-row>

            <hr>
            <span class="font-medium-2">Constraining/Defining Standards & References</span>
            <!-- Standards/References/Protocols -->
            <b-row class="my-2">
              <b-col
                v-if="areStandardsLoading"
                cols="5"
                class="d-flex ml-1"
              >
                <b-spinner
                  small
                  class="align-self-center mr-1"
                />
                Linked Source End Standards
              </b-col>
              <b-col v-else cols="6">
                <list-group-entity
                  v-if="selectedInterface"
                  label="Source End Standards"
                  :entity-array="interfaceStandardsSource"
                  modal="associate-source-ref-modal"
                />
              </b-col>

              <b-col
                v-if="areStandardsLoading"
                cols="6"
                class="d-flex justify-content-center ml-1"
              >
                <b-spinner
                  small
                  class="align-self-center mr-1"
                />
                Linked Target End Standards
              </b-col>
              <b-col v-else cols="6">
                <list-group-entity
                  v-if="selectedInterface"
                  label="Target End Standards"
                  :entity-array="interfaceStandardsTarget"
                  modal="associate-target-ref-modal"
                />
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col
                v-if="areStandardsLoading"
                class="d-flex ml-1"
              >
                <b-spinner
                  small
                  class="align-self-center mr-1"
                />
                Linked Exchanged Resource Standards
              </b-col>
              <b-col v-else>
                <list-group-entity
                  v-if="selectedInterface"
                  label="Exchanged Resource Standards"
                  :entity-array="interfaceStandards"
                  modal="associate-interface-ref-modal"
                />
              </b-col>
            </b-row>
            <hr>

            <!-- Behaviours involving Interface -->
            <span class="font-medium-2">Behaviours Involving Interface</span>
            <b-list-group
              v-if="interfaceBehaviours.length > 0"
            >
              <ListGroupItemBN
                v-for="(item,index) in interfaceBehaviours"
                :key="index"
                :bn="item"
              />
            </b-list-group>

            <div v-else>
              <span class="ml-3 font-small-3 text-muted">No behaviours involving interface</span>
            </div>

          </b-tab>

          <b-tab>
            <!-- Avoid wrapping that messes with the alignment -->
            <template #title>
              <span class="text-nowrap font-small-4">Resource Definition</span>
            </template>
            <div v-if="resource">
              <list-group-property :entity-array="interfaceResourceDefinition" :entity="resource" @propertyChanged="updateProperties" />
            </div>
          </b-tab>

        </b-tabs>
      </template>
    </b-sidebar>

    <ReleaseAllocate @updated="interfaceStorePull" />
    <Associator
      id="associate-interface-release-modal"
      title="Allocate Releases to Interface"
      left-label="All Releases"
      :left-opts="releasesLeft"
      right-label="Allocated Releases"
      :right-opts="releasesRight"
      @ok="linkInterfaceReleases"
    />
    <Associator
      id="associate-interface-fn-modal"
      title="Associate Interface with Functions"
      left-label="All Functions"
      :left-opts="functionsLeft"
      right-label="Allocated Functions"
      :right-opts="functionsRight"
      @ok="linkInterfaceFunctions"
    />
    <Associator
      id="associate-interface-ref-modal"
      title="Interface Resource Protocol defined by Standards/References"
      left-label="All Standards/References"
      :left-opts="standardsLeft"
      right-label="Allocated Standards/References"
      :right-opts="standardsRight"
      @ok="linkinterfaceStandards"
    />
    <Associator
      id="associate-source-ref-modal"
      title="Interface Source End defined by Standards/References"
      left-label="All Standards/References"
      :left-opts="standardsLeft"
      right-label="Allocated Standards/References"
      :right-opts="standardsRightSrc"
      @ok="linkinterfaceStandardsSource"
    />
    <Associator
      id="associate-target-ref-modal"
      title="Interface Target End defined by Standards/References"
      left-label="All Standards/References"
      :left-opts="standardsLeft"
      right-label="Allocated Standards/References"
      :right-opts="standardsRightTgt"
      @ok="linkinterfaceStandardsTarget"
    />
    <Associator
      id="associate-interface-req-modal"
      title="Associate Interface with Requirements"
      left-label="All Requirements"
      :left-opts="requirementsLeft"
      right-label="Allocated Requirements"
      :right-opts="requirementsRight"
      @ok="linkInterfaceRequirements"
    />
    <Associator
      id="associate-interface-issue-modal"
      title="Associate Interface with Issues"
      left-label="All Issues"
      :left-opts="issuesLeft"
      right-label="Allocated Issues"
      :right-opts="issuesRight"
      @ok="linkInterfaceIssues"
    />
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BSidebar } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { email, required, url } from '@validations'
import { avatarText } from '@core/utils/filter'
import {
  computed, onUnmounted, ref, watch,
} from '@vue/composition-api'
import { mapState } from 'vuex'
import store from '@/store'
import ReleaseAllocate from '@/components/Domain/Modals/Interfaces/ReleaseAllocate.vue'
import Associator from '@/components/Forms/M_Associator.vue'
import ListGroupEntity from '@/components/Forms/ListGroups/ListGroupEntity.vue'
import ListGroupRequirement from '@/components/Forms/ListGroups/ListGroupRequirement.vue'
import ListGroupRelease from '@/components/Forms/ListGroups/ListGroupRelease.vue'
import interfacesStoreModule from '@/apps/Interfaces/interfacesStoreModule'
import ListGroupProperty from '@/components/Forms/ListGroups/ListGroupProperty.vue'
import ListGroupItemBN from '@/components/Forms/ListGroupItems/ListGroupItemBN.vue'
import DeleteInterfaceModal from '@/components/Interfaces/Modals/DeleteInterfaceModal.vue'
import ListGroupItemIssue from '@/components/Forms/ListGroupItems/ListGroupItemIssue.vue'

export default {
  components: {
    ListGroupItemIssue,
    // BSV
    // BButton,
    BSidebar,
    // BForm,
    BFormGroup,
    BFormInput,
    // BAvatar,
    // BFormInvalidFeedback,

    // 3rd party packages
    vSelect,
    // flatPickr,
    // quillEditor,

    // Form Validation
    // ValidationProvider,
    // ValidationObserver,
    DeleteInterfaceModal,
    ReleaseAllocate,
    Associator,
    ListGroupEntity,
    ListGroupRequirement,
    ListGroupRelease,
    ListGroupProperty,

    ListGroupItemBN,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isInterfaceHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isInterfaceHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    interfaceProp: {
      type: Object,
      required: true,
    },
    clearInterfaceData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
    }
  },
  computed: {
    ...mapState({
      components: state => state.domainModel.components,
      stakeholders: state => state.canvas.stakeholders,
      // requirements: state => state.requirements.requirements,
    }),
    performerOpts() {
      return this.components.map(x => ({ value: x.id, text: x.name }))
    },
    resourceOpts() {
      return this.components.map(x => ({ value: x.id, text: x.name }))
    },
    resource() {
      console.log('Getting: ', this.localInterfaceProp, this.endpoints)
      const resourceID = this.localInterfaceProp.resource
      return this.endpoints[resourceID]
    },
  },
  setup(props, { emit }) {
    // Register the template store first
    const INTERFACE_STORE_MODULE_NAME = 'app-interfaces'

    // Register module
    if (!store.hasModule(INTERFACE_STORE_MODULE_NAME)) store.registerModule(INTERFACE_STORE_MODULE_NAME, interfacesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INTERFACE_STORE_MODULE_NAME)) store.unregisterModule(INTERFACE_STORE_MODULE_NAME)
    })

    // Init the variables before changing their data otherwise error
    // -- Local version of the prop that can be used to v-model inputs
    const localInterfaceProp = ref({})

    // -- Selected Interface
    const selectedInterface = ref({})
    const functionsRight = ref([])
    const interfaceFunctions = ref([])
    const interfaceReleases = ref([])
    const requirementsRight = ref([])
    const issuesRight = ref([])
    const interfaceRequirements = ref([])
    const interfaceIssues = ref([])
    const interfaceResourceDefinition = ref([])
    const interfaceStandards = ref([])
    const interfaceStandardsSource = ref([])
    const interfaceStandardsTarget = ref([])
    const standardsRight = ref([])
    const standardsRightSrc = ref([])
    const standardsRightTgt = ref([])
    const interfaceBehaviours = ref([])
    const endpoints = ref({})

    // -- Non interface store states
    const functions = ref([])
    const functionsLeft = ref([])
    const standards = ref([])
    const standardsLeft = ref([])
    const requirements = ref([])
    const requirementsLeft = ref([])
    const issues = ref([])
    const issuesLeft = ref([])
    const releases = ref([])
    const releasesLeft = ref([])
    const releasesRight = ref([])

    // Store Functions
    // -- Selected interface store dispatch and related variables
    function interfaceStorePull() {
      if (localInterfaceProp.value.id) {
        store.dispatch('interfaces/selectInterface', localInterfaceProp.value.id)
          .then(() => {
            if (store.state.interfaces.selectedInterface) {
              // Set interface
              selectedInterface.value = store.state.interfaces.selectedInterface
              console.log('Pulling Selected: ', selectedInterface)

              // Associated Functions
              interfaceFunctions.value = selectedInterface.value.functions
              functionsRight.value = selectedInterface.value.functions.map(x => ({ value: x.id, text: x.name }))

              // Associated Standards
              interfaceStandards.value = selectedInterface.value.standards
              interfaceStandardsSource.value = selectedInterface.value.source_standards
              interfaceStandardsTarget.value = selectedInterface.value.target_standards
              standardsRight.value = selectedInterface.value.standards.map(x => ({ value: x.id, text: x.name }))
              standardsRightSrc.value = selectedInterface.value.source_standards.map(x => ({ value: x.id, text: x.name }))
              standardsRightTgt.value = selectedInterface.value.target_standards.map(x => ({ value: x.id, text: x.name }))

              // Associated Releases
              interfaceReleases.value = selectedInterface.value.releases
              releasesRight.value = selectedInterface.value.releases.map(x => ({
                value: x.id,
                text: x.name,
              }))

              // Associated Requirements
              interfaceRequirements.value = selectedInterface.value.reqs
              requirementsRight.value = selectedInterface.value.reqs.map(x => ({
                value: x.id,
                text: `${x.display_id} - ${x.object_text}`,
                display_id: x.display_id,
                object_text: x.object_text,
                priority: x.priority,
              }))

              // Associated Issues
              interfaceIssues.value = selectedInterface.value.issues
              issuesRight.value = selectedInterface.value.issues.map(x => ({
                value: x.id,
                text: `${x.display_id}`,
              }))

              // Associated Behaviours
              interfaceBehaviours.value = selectedInterface.value.behaviours

              // Resource Definition (Attribuites)
              interfaceResourceDefinition.value = selectedInterface.value.resource_definition

              // Get the endpoints
              endpoints.value = selectedInterface.value.endpoints
            }
          })
      }
    }

    // -- Non interface store dispatches and variable assignments
    const areFunctionsLoading = ref(false)
    const areStandardsLoading = ref(false)
    const areRequirementsLoading = ref(false)
    const areIssuesLoading = ref(false)
    const areReleasesLoading = ref(false)
    function getAssociatorArrayData() {
      areFunctionsLoading.value = true
      areStandardsLoading.value = true
      store.dispatch('domainModel/getComponents')
        .then(() => {
          if (store.state.domainModel.functions) {
            functions.value = store.state.domainModel.functions
            functionsLeft.value = functions.value.map(x => ({ value: x.id, text: x.name }))
            areFunctionsLoading.value = false
          }
          if (store.state.domainModel.standards) {
            standards.value = store.state.domainModel.standards
            standardsLeft.value = standards.value.map(x => ({ value: x.id, text: x.name }))
            areStandardsLoading.value = false
          }
        })
      areReleasesLoading.value = true
      store.dispatch('releases/getReleases')
        .then(() => {
          if (store.state.releases.releases) {
            releases.value = store.state.releases.releases
            releasesLeft.value = releases.value.map(x => ({
              value: x.id,
              text: x.name,
            }))
            areReleasesLoading.value = false
          }
        })
      areRequirementsLoading.value = true
      store.dispatch('requirements/getRequirementsSimple')
        .then(() => {
          if (store.state.requirements.requirements) {
            requirements.value = store.state.requirements.requirements
            requirementsLeft.value = requirements.value.map(x => ({
              value: x.id,
              text: `${x.display_id} - ${x.text}`,
              display_id: x.display_id,
              object_text: x.text,
              priority: x.priority,
            }))
            areRequirementsLoading.value = false
          }
        })
      areIssuesLoading.value = true
      store.dispatch('issues/getIssues')
        .then(() => {
          if (store.state.issues.issues) {
            issues.value = store.state.issues.issues
            issuesLeft.value = issues.value.map(x => ({
              value: x.id,
              text: `${x.display_id}`,
            }))
            areIssuesLoading.value = false
          }
        })
    }

    // Set the actual local instance of the interface prop here
    // Note: if statement prevents localInterfaceProp from being null
    // -- As all other variables depend on localInterfaceProp, they get assigned after a local prop is created successfully
    function updateLocalProp() {
      if (props.interfaceProp) {
        // Only if localInterfaceProp actually exists, otherwise you get errors
        localInterfaceProp.value = JSON.parse(JSON.stringify(props.interfaceProp))
        interfaceStorePull()
        getAssociatorArrayData()
      }
    }

    watch(() => props.interfaceProp, newVal => {
      // Create a deep copy of the prop and run all store dispatch functions
      updateLocalProp()
    })
    // Run at least once (watch doesn't do it always)
    updateLocalProp()

    // Store
    store.dispatch('canvas/fetchStakeholders') // Runs with no issue so far

    // Methods
    const linkInterfaceFunctions = (event, value) => {
      const allocatedFns = value.map(x => x.value)
      const payload = {
        id: localInterfaceProp.value.id,
        associatorData: {
          model: store.state.model.id,
          functions: allocatedFns,
        },
      }
      store.dispatch('app-interfaces/updateFunctionAllocations', payload)
        .then(() => {
          interfaceStorePull()
        })
    }

    const linkinterfaceStandards = (event, value) => {
      const allocatedRefs = value.map(x => x.value)
      const payload = {
        id: localInterfaceProp.value.id,
        associatorData: {
          model: store.state.model.id,
          standards: allocatedRefs,
          qualification_type: 'resource',
        },
      }
      store.dispatch('app-interfaces/updateStandardsLinking', payload)
        .then(() => {
          interfaceStorePull()
        })
    }

    const linkinterfaceStandardsTarget = (event, value) => {
      const allocatedRefs = value.map(x => x.value)
      const payload = {
        id: localInterfaceProp.value.id,
        associatorData: {
          model: store.state.model.id,
          standards: allocatedRefs,
          qualification_type: 'target',
        },
      }
      store.dispatch('app-interfaces/updateStandardsLinking', payload)
        .then(() => {
          interfaceStorePull()
        })
    }

    const linkinterfaceStandardsSource = (event, value) => {
      const allocatedRefs = value.map(x => x.value)
      const payload = {
        id: localInterfaceProp.value.id,
        associatorData: {
          model: store.state.model.id,
          standards: allocatedRefs,
          qualification_type: 'source',
        },
      }
      store.dispatch('app-interfaces/updateStandardsLinking', payload)
        .then(() => {
          interfaceStorePull()
        })
    }

    const linkInterfaceRequirements = (event, value) => {
      const allocatedReqs = value.map(x => x.value)
      const payload = {
        id: localInterfaceProp.value.id,
        associatorData: {
          model: store.state.model.id,
          reqs: allocatedReqs,
        },
      }
      store.dispatch('app-interfaces/updateRequirementsAllocations', payload)
        .then(() => {
          interfaceStorePull()
        })
    }

    const linkInterfaceIssues = (event, value) => {
      const payload = {
        issues: value.map(x => x.value),
        target_id: localInterfaceProp.value.id,
        label: 'Interface',
      }
      store.dispatch('issues/allocateIssues', payload)
        .then(() => {
          interfaceStorePull()
        })
    }

    const linkInterfaceReleases = (event, value) => {
      const allocatedReleases = value.map(x => x.value)
      const getPayload = {
        id: localInterfaceProp.value.id,
        model: store.state.model.id,
      }
      store.dispatch('app-interfaces/getAllocatedReleases', getPayload)
        .then(({ data }) => {
          const payload = {
            toRemove: {
              interface: localInterfaceProp.value.id,
              model: store.state.model.id,
              releases: data.map(x => x.id),
            },
            toAdd: {
              interface: localInterfaceProp.value.id,
              model: store.state.model.id,
              releases: allocatedReleases,
            },
          }
          store.dispatch('app-interfaces/updateReleasesAllocations', payload)
            .then(() => interfaceStorePull())
        })
    }

    const updateIdentification = () => {
      const payload = {
        id: localInterfaceProp.value.id,
        display_id: localInterfaceProp.value.display_id, // To be used in the toast
        // Actual payload
        interfaceProperties: {
          display_id: localInterfaceProp.value.display_id,
          name: localInterfaceProp.value.name,
          version: localInterfaceProp.value.version,
          description: localInterfaceProp.value.description,
          source: localInterfaceProp.value.source,
          target: localInterfaceProp.value.target,
          resource: localInterfaceProp.value.resource,
          status: localInterfaceProp.value.status,
          classification: localInterfaceProp.value.classification || this.$store.state.model.defaultSecurityClassification,
        },
      }
      emit('identification', payload)
    }

    const updateCharacteristics = () => {
      const payload = {
        id: localInterfaceProp.value.id,
        display_id: localInterfaceProp.value.display_id, // To be used in the toast
        // Actual payload
        interfaceProperties: {
          type: localInterfaceProp.value.type,
          continuous: localInterfaceProp.value.continuous,
          synchronous: localInterfaceProp.value.synchronous,
          frequency: localInterfaceProp.value.frequency,
          connection: localInterfaceProp.value.connection,
          owner: localInterfaceProp.value.owner,
          irl: localInterfaceProp.value.irl,
          notes: localInterfaceProp.value.notes,
          volume_avg: localInterfaceProp.value.volume_avg,
          volume_peak: localInterfaceProp.value.volume_peak,
          latency_avg: localInterfaceProp.value.latency_avg,
          latency_peak: localInterfaceProp.value.latency_peak,
          emp_max: localInterfaceProp.value.emp_max,
          timeout: localInterfaceProp.value.timeout,
          retry_count: localInterfaceProp.value.retry_count,
          retry_interval: localInterfaceProp.value.retry_interval,
          exception_handling: localInterfaceProp.value.exception_handling,
        },
      }
      emit('characteristics', payload)
    }

    const updateProperties = () => {
      console.log('Update Properties View')
      interfaceStorePull()
    }

    return {
      // Filter/Formatter
      avatarText,

      // Variables
      localInterfaceProp,

      // Store stuff
      // -- State
      // -- -- Selected Interface
      selectedInterface,
      interfaceFunctions,
      interfaceReleases,
      interfaceRequirements,
      interfaceIssues,
      interfaceResourceDefinition,
      endpoints,
      interfaceStandards,
      interfaceStandardsSource,
      interfaceStandardsTarget,
      interfaceBehaviours,

      // -- -- Non Interface Stuff
      functions,
      functionsLeft,
      functionsRight,
      requirements,
      requirementsLeft,
      requirementsRight,
      issues,
      issuesLeft,
      issuesRight,
      standards,
      standardsLeft,
      standardsRight,
      standardsRightSrc,
      standardsRightTgt,
      releasesLeft,
      releasesRight,

      // -- Loading status stuff
      areFunctionsLoading,
      areStandardsLoading,
      areRequirementsLoading,
      areIssuesLoading,

      // -- Getters (works as is, no need to change)
      irls: computed(() => store.getters['constants/irls']),
      interfaceTypes: computed(() => store.getters['constants/interfaceTypes']),
      interfaceStatus: computed(() => store.getters['constants/interfaceStatus']),
      securityClassifications: computed(() => store.getters['constants/securityClassifications']),

      // Methods
      updateIdentification,
      updateCharacteristics,
      updateProperties,

      // Associator
      linkInterfaceFunctions,
      linkInterfaceRequirements,
      linkInterfaceIssues,
      linkinterfaceStandards,
      linkinterfaceStandardsSource,
      linkinterfaceStandardsTarget,
      linkInterfaceReleases,

      // Store
      interfaceStorePull,

      // Local Prop
      updateLocalProp,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
