<template>
  <b-list-group-item
    v-if="release"
    class="release"
    button
    @click="goToRelease"
  >
    <div class="d-inline-flex w-100 justify-content-between">
      <h5 class="text-primary">
        {{ release.name }}
      </h5>
      <p>
        Release date:
        <span v-if="release.end_date" class="text-primary">{{ release.end_date | formatDate }}</span>
        <span v-else class="text-muted">(not set)</span>
      </p>
    </div>
    <div class="font-small-3">
      {{ release.description }}
    </div>
  </b-list-group-item>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import { formatDate } from '../../../@core/utils/filter'

export default {
  name: 'ListGroupItemRelease',
  methods: { formatDate },
  props: {
    release: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { router } = useRouter()

    const goToRelease = () => {
      router.push({
        name: 'model_releases',
        params: { release: props.release.id },
      })
    }

    return {
      goToRelease,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.release {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.release:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .release {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .release:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
