<template>
  <b-modal
    id="delete-attribute-modal"
    title="Delete Attribute"
    size="lg"
    centered
    @ok="deleteSelectedAttribute"
  >
    <p>
      Are you sure you want to delete the attribute:
      <span class="text-primary font-weight-bolder">{{ selected_attribute.name }}</span>
      ?
    </p>

    <p class="text-danger font-weight-bolder">
      This action cannot be undone.
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-dark" @click="cancel()">
        No, keep it
      </b-button>
      <b-button variant="danger" @click="ok()">
        Yes, delete it
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('domainModel', ['selected_entity2', 'selected_attribute']),
  },
  methods: {
    deleteSelectedAttribute(evt) {
      evt.preventDefault()
      const payload = {
        id: this.selected_attribute.id,
        model: this.$store.state.model.id,
      }
      this.$http.post('/api/v2/domain_model/del_attribute', payload).then(() => {
        const selectedId = this.selected_entity2.context.details.id
        this.$bvModal.hide('delete-attribute-modal')
        this.$store.dispatch('domainModel/selectEntity2', selectedId)
      })
    },

  },
}
</script>
