var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"export-interfaces-modal","title":"Export Interfaces to XLSX","size":"xl","no-close-on-backdrop":"","no-close-on-esc":""},on:{"ok":_vm.generateTable,"shown":_vm.onShown},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Dismiss ")]),_c('b-button',{attrs:{"variant":"success","disabled":_vm.loading_status},on:{"click":function($event){return ok()}}},[(_vm.loading_status)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" Exporting... ")],1):_c('span',[_vm._v(" Export Interfaces ")])])]}}])},[_c('h5',{staticClass:"mt-50 text-primary"},[_vm._v(" Select the interfaces to export ")]),(_vm.loaded)?_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"colPadding",attrs:{"items":_vm.allInterfaces,"fields":_vm.interfaceFields,"small":"","hover":"","responsive":"","no-border-collapse":"","sticky-header":"80vh"},on:{"row-clicked":_vm.toggleInterface},scopedSlots:_vm._u([{key:"head(selected)",fn:function(){return [_c('b-form-checkbox',{on:{"input":_vm.selectAll},model:{value:(_vm.global),callback:function ($$v) {_vm.global=$$v},expression:"global"}})]},proxy:true},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [_c('b-form-checkbox',{staticStyle:{"pointer-events":"none"},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"cell(display_id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.interface_properties.display_id || '-')+" ")]}}],null,false,1890697957)})],1)],1):_c('b-overlay',{attrs:{"show":"","opacity":"0"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('h1',{staticStyle:{"display":"inline-block"}},[_vm._v(" Loading Interfaces ")]),_vm._v(" "),_c('b-spinner')]},proxy:true}])},[_c('div',{staticStyle:{"height":"50vh"}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }