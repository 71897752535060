<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content todo-sidebar">
        <div class="todo-app-menu">
          <div class="add-task">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              block
              @click="$bvModal.show('add-interface-modal')"
            >
              Add Interface
            </b-button>
          </div>
          <div class="sidebar-menu-list scroll-area">
            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                :to="allInterfaces.route"
                :active="isDynamicRouteActive(allInterfaces.route)"
                @click="$emit('close-left-sidebar')"
              >
                <font-awesome-icon
                  :icon="allInterfaces.icon"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ allInterfaces.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Status
              </h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                v-for="status in interfaceStatus"
                :key="status.title + $route.path"
                :to="status.route"
                :active="isDynamicRouteActive(status.route)"
                @click="$emit('close-left-sidebar')"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${status.color}`"
                />
                <span>{{ status.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1">
                Type
              </h6>
            </div>

            <b-list-group class="list-group-filters">
              <b-list-group-item
                v-for="type in interfaceTypes"
                :key="type.title + $route.path"
                :to="type.route"
                :active="isDynamicRouteActive(type.route)"
                @click="$emit('close-left-sidebar')"
              >
                <font-awesome-icon
                  :icon="type.icon"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ type.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Tags -->
            <div class="mt-2 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-50">
                Integration Readiness Level
              </h6>
            </div>

            <b-dropdown
              variant="outline-primary"
              text="IRL"
              title="Filter by Integration Readiness Level"
              size="sm"
              dropup
              block
              boundary="window"
              :popper-opts="{positionFixed:true}"
            >
              <b-dropdown-item
                v-for="tag in interfaceIrls"
                :key="tag.title + $route.path"
                :to="tag.route"
                :active="isDynamicRouteActive(tag.route)"
                :title="tag.title"
                @click="$emit('close-left-sidebar')"
              >
                {{ tag.title }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const allInterfaces = { title: 'All Interfaces', icon: ['fas', 'bezier-curve'], route: { name: 'interfaces' } }

    const interfaceStatus = [
      { title: 'Proposed', color: 'primary', route: { name: 'interfaces_status', params: { status: 'proposed' } } },
      { title: 'In-Review', color: 'primary', route: { name: 'interfaces_status', params: { status: 'in-review' } } },
      { title: 'Confirmed', color: 'primary', route: { name: 'interfaces_status', params: { status: 'confirmed' } } },
      { title: 'Developed', color: 'primary', route: { name: 'interfaces_status', params: { status: 'developed' } } },
      { title: 'Tested', color: 'primary', route: { name: 'interfaces_status', params: { status: 'tested' } } },
      { title: 'Deployed', color: 'primary', route: { name: 'interfaces_status', params: { status: 'deployed' } } },
    ]

    const interfaceTypes = [
      { title: 'Structural/Mechanical', icon: ['fas', 'wrench'], route: { name: 'interfaces_type', params: { type: 'structural/mechanical' } } },
      { title: 'Fluid', icon: ['fas', 'tint'], route: { name: 'interfaces_type', params: { type: 'fluid' } } },
      { title: 'Electrical (Power)', icon: ['fas', 'bolt'], route: { name: 'interfaces_type', params: { type: 'electrical.*' } } },
      { title: 'Electronic (Signal)', icon: ['fas', 'satellite-dish'], route: { name: 'interfaces_type', params: { type: 'electronic.*' } } },
      { title: 'Software and Data', icon: ['fas', 'desktop'], route: { name: 'interfaces_type', params: { type: 'software and data' } } },
      { title: 'Environment', icon: ['fas', 'seedling'], route: { name: 'interfaces_type', params: { type: 'environment' } } },
      { title: 'Other', icon: ['fas', 'ellipsis-h'], route: { name: 'interfaces_type', params: { type: 'other' } } },
    ]

    const interfaceIrls = [
      { title: '1. An interface between technologies has been identified with sufficient detail to allow characterization of the relationship', color: 'primary', route: { name: 'interfaces_irl', params: { irl: '1' } } },
      { title: '2. There is some level of specificity to characterize the interaction between technologies through their interface', color: 'success', route: { name: 'interfaces_irl', params: { irl: '2' } } },
      { title: '3. There is compatibility between technologies to orderly and efficiently integrate and interact', color: 'warning', route: { name: 'interfaces_irl', params: { irl: '3' } } },
      { title: '4. There is sufficient detail in the quality and assurance of the integration between technologies', color: 'danger', route: { name: 'interfaces_irl', params: { irl: '4' } } },
      { title: '5. There is sufficient control between technologies necessary to establish, manage, and terminate the integration', color: 'info', route: { name: 'interfaces_irl', params: { irl: '5' } } },
      { title: '6. The integrating technologies can accept, translate, and structure information for its intended application', color: 'info', route: { name: 'interfaces_irl', params: { irl: '6' } } },
      { title: '7. The integration of technologies has been verified and validated with sufficient detail to be actionable.', color: 'info', route: { name: 'interfaces_irl', params: { irl: '7' } } },
      { title: '8. Actual integration completed and mission qualified through test and demonstration in the system environment', color: 'info', route: { name: 'interfaces_irl', params: { irl: '8' } } },
      { title: '9. Execute a support program that meets operational support performance requirements and sustains the system in the most cost-effective manner over its total life cycle', color: 'info', route: { name: 'interfaces_irl', params: { irl: '9' } } },
      { title: 'Other', color: 'info', route: { name: 'interfaces_irl', params: { irl: 'other' } } },
    ]

    return {
      allInterfaces,
      interfaceStatus,
      interfaceTypes,
      interfaceIrls,
      isDynamicRouteActive,
    }
  },
}
</script>

<style>

</style>
