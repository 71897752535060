<template>
  <b-modal
    id="delete-interface-modal"
    title="Delete Interface"
    centered
    @ok="deleteSelectedInterface"
  >
    <p>
      Deleting the
      <span class="text-primary font-weight-bolder">
        <span v-if="selectedInterface.display_id && selectedInterface.display_id !== ''">
          {{ selectedInterface.display_id }} - {{ selectedInterface.name }}
        </span>
        <span v-else>
          {{ selectedInterface.name }}
        </span>
      </span>
      interface cannot be undone.
    </p>
    <p class="mb-0 font-weight-bolder">
      Are you sure?
    </p>

    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="outline-secondary" @click="cancel()">
        Dismiss
      </b-button>
      <b-button variant="danger" @click="ok()">
        Delete Interface
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      selectedInterface: state => state.interfaces.selectedInterface,
    }),
  },
  methods: {
    deleteSelectedInterface(evt) {
      evt.preventDefault()
      this.$store.dispatch('interfaces/deleteInterface', this.selectedInterface.id).then(() => {
        this.$bvModal.hide('delete-interface-modal')
        this.$emit('deleted')
      })
    },

  },
}
</script>
